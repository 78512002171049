import React from 'react';
import { FieldError } from 'react-hook-form';
import { S } from './Select.styles';

interface Props extends React.InputHTMLAttributes<HTMLSelectElement> {
  label: string;
  error?: FieldError | undefined;
}

export const Select = React.forwardRef<HTMLSelectElement, Props>(
  (props, ref) => {
    const { label, ...rest } = props;
    console.log(ref);
    return (
      <div>
        <S.Wrapper>
          <S.Label>{label}</S.Label>
          <S.Select {...rest} ref={ref} />
        </S.Wrapper>
        {props.error && (
          <S.Error>
            <S.B3 $color="error">{props.error.message}</S.B3>
          </S.Error>
        )}
      </div>
    );
  }
);
